import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import errorImg from './images/error.png';

class Redirector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            link: null,
        }
    }
    componentWillMount = () => {
        firebase.firestore().collection('urlShortener').doc(this.props.match.params.hash).get().then(doc => {
            let values = doc.data();

            if (values !== undefined) {
                window.location.href = values.redirect;
            } else {
                this.setState({
                    link: 'no'
                })
            }
        });
    }

    renderRedirect = () => {
        if (this.state.link === null) {
            return (
                'Verificando redirecionando...'
            )
        } else if (this.state.link === 'no') {
            return (
                <div style={{marginTop:40}}>
                    <img src={errorImg} alt="logo-error" style={{maxWidth:200}} />
                    <h2 style={{fontFamily: 'Arial'}}>Não encontramos a página que você está procurando!</h2>
                    <p style={{color: '#50AFA0', fontFamily: 'Arial'}}>Se estiver com dúvidas sobre o agendamento, sugerimos entrar em contato com o profissional de saúde.</p>
                </div>
            )
        } else {
            return (
                'Redirecionando...'
            )
        }
    }

    render = () => {
        return (
            <center>
                {this.renderRedirect()}
            </center>
        );
    }
}

export default Redirector;