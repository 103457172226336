import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import firebase from 'firebase/app';
import 'firebase/firestore';

import Redirector from './Redirector';

class App extends Component {
  componentWillMount = () => {
    firebase.initializeApp({
      apiKey: "AIzaSyBEZUPXsp2WtMqOHUypweybcUoglEpJ6Ic",
      authDomain: "estela-pro.firebaseapp.com",
      storageBucket: "gs://estela-pro.appspot.com",
      projectId: "estela-pro"
    });
  }

  render = () => {
    return (
      <Switch>
        <Route path='/:hash' component={Redirector} />
      </Switch>
    );
  }
}

export default App;
